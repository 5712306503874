import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import OnHoverScrollContainer from "./CustomScrollDiv";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import artist1 from './images/Artists/bp_alessandro_safina.png';
import artist2 from './images/Artists/bp_ayhan_sicimoglu.png';
import artist3 from './images/Artists/bp_aysegul_aldinc.png';
import artist4 from './images/Artists/bp_bgko.png';
import artist5 from './images/Artists/bp_cristina_ramos.png';
import artist6 from './images/Artists/bp_dhafer_youssef.png';
import artist7 from './images/Artists/bp_emir_ersoy.png';
import artist8 from './images/Artists/bp_gipsy_kings.png';
import artist9 from './images/Artists/bp_kovacs.png';
import artist10 from './images/Artists/bp_mfo.png';
import artist11 from './images/Artists/bp_nil_karaibrahimgil.png';
import artist12 from './images/Artists/bp_placido_domingo.png';
import artist13 from './images/Artists/bp_serdar_ortac.png';
import artist14 from './images/Artists/bp_suzan_kardes.png';
import artist15 from './images/Artists/bp_yonca_lodi.png';
import artist16 from './images/Artists/bp_yuksek_sadakat.png';
import artist17 from './images/Artists/bp_zara.png';
import artist18 from './images/Artists/exc_buika.png';
import artist19 from './images/Artists/bp_goran.png';
import artist20 from './images/Artists/bp_yasmin_levy.png';
import artist21 from './images/Artists/bp_evgeny.png';
import artist22 from './images/Artists/bp_buyuk_ev_ablukada.png';
import artist23 from './images/Artists/bp_karsu.png';
import artist24 from './images/Artists/bp_mor_ve_otesi.png';



const bpArtist = [
    {photo: artist12, name:"Placido Domingo"},
    {photo: artist8, name:"Gipsy Kings"},
    {photo: artist18, name:"Buika"},
    {photo: artist19, name:"Goran Bregovic"},
    {photo: artist20, name:"Yasmin Levy"},
    {photo: artist1, name:"Alessandro Safina"},
    {photo: artist6, name:"Dhafer Youssef"},
    {photo: artist9, name:"Kovacs"},
    {photo: artist21, name:"Evgeny Grinko"},

    // {photo: artist4, name:"Barcelona Gipsy Balkan Orchestra"},
    // {photo: artist5, name:"Cristina Ramos"},

    {photo: artist2, name:"Ayhan Sicimoğlu & Latin All Star"},
    {photo: artist3, name:"Ayşegül Aldinç"},
    {photo: artist22, name:"Büyük Ev Ablukada"},
    {photo: artist7, name:"Emir Ersoy"},
    {photo: artist23, name:"Karsu"},
    {photo: artist10, name:"MFÖ"},
    {photo: artist24, name:"Mor ve Ötesi"},
    {photo: artist11, name:"Nil Karaibrahimgil"},
    {photo: artist13, name:"Serdar Ortaç"},
    {photo: artist14, name:"Suzan Kardeş"},
    {photo: artist15, name:"Yonca Lodi"},
    {photo: artist16, name:"Yüksek Sadakat"},
    {photo: artist17, name:"Zara"}
    



];
const titleStyle={
    fontFamily:'Poppins',
    fontWeight: 600,
    padding:5,
    margin:0,
    color: 'white',

}
const ContainerStyle = {
    
    marginTop: 30,
    marginBottom: 20,
    width: '100%',
    padding: 0,
    height: 800,
    
}
const GridStyle = {
    margin:0,
    width: '100%',
    paddingRight: 15,
}

const ArtistCard = ( bpArtist, index ) => {

    return (
      <>
        <Grid key={index} item sx={{pt:"10px !important", pr: "5px",}} >
            <Card sx={{ width: 310, height: 240, borderRadius: 0, mb:5,}}>
                <img src={bpArtist.photo} width={'100%'}/>
                <CardContent sx={{background:'#46328F', p:"0px !important", display: 'flex',  justifyContent: 'center' }}>
                    <Typography style={titleStyle}>
                        {bpArtist.name}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>

      </>
    )
  }

export default function ArtistListComp() {
  return (
    <div style={{paddingBottom: 20, paddingLeft: 10, paddingRight: 10}}>
        <Container style={ContainerStyle} maxWidth="xxl">
            <OnHoverScrollContainer>
                <Grid container  direction="row" justifyContent="flex-start" alignItems="center" style={GridStyle} spacing={5}>
                    {bpArtist.map(ArtistCard)}                                                
                </Grid>
            </OnHoverScrollContainer>       
        </Container>
    </div>
  )
}
