import React from 'react'
import BigSlider from "./BigSlider";
import {useTranslation} from "react-i18next";

import { useMediaQuery, useTheme, } from "@mui/material";


  const containerLargeStyles = {
    width: "100%",
    height: "600px",
    margin: "0 auto",
  };
  const containerSmallStyles = {
    width: "100%",
    height: "400px",
    margin: "0 auto",
  };


function SliderComp() {

    const theme = useTheme();
    const isDownMD = useMediaQuery(theme.breakpoints.down("md"));
    const {t, i18n}= useTranslation();

    const slides = [
      { url: t('slider.slider2Big'), title: "Buika", link:"" },
      { url: t('slider.slider1Big'), title: "Simge", link: "www.google.com" },
      { url: t('slider.slider3Big'), title: "Monica Molina", link: "www.yahoo.com" },
      { url: t('slider.slider4Big'), title: "Fuat Güner AVM", link: "www.google.com" },
      // { url: slider_4, title: "Turgut Cingi" },
  
    ];
    const smallSlides = [
      { url: t('slider.slider2Small'), title: "Buika", link:"" },
      { url: t('slider.slider1Small'), title: "Simge", link: "www.google.com" },
      { url: t('slider.slider3Small'), title: "Monica Molina", link: "www.yahoo.com" },
      { url: t('slider.slider4Small'), title: "Fuat Güner AVM", link: "www.google.com" },
      // { url: kucukSlider_4, title: "Turgut Cingi" },
  
  
    ];
  return (
    <>
    {isDownMD ? ( 
        <div style={containerSmallStyles}>
            <BigSlider slides={smallSlides}/>
        </div>
    ):(
        <div style={containerLargeStyles}>
            <BigSlider slides={slides}/>
        </div>
    )}
    </>
    
  )
}

export default SliderComp