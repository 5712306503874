import React from 'react'
import ArtistAlbumCard from './ArtistAlbumCard'
import ArtistAlbumCardSmall from './ArtistAlbumCardSmall'

import Container from '@mui/material/Container'
import { useMediaQuery, useTheme, } from "@mui/material";


const containerStyle = {
  height: 'auto',
  width: 'auto',
  marginBottom: 50,

}


function Music() {

  const theme = useTheme();
  const isDownMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>

      {isDownMD ? ( 
          <Container style={containerStyle} maxWidth="lg">
            <ArtistAlbumCardSmall sanatciAdi="Monica Molina" albumAdi="De Mi Esperanza" spofityLinki="https://open.spotify.com/embed/track/79CbSlh6Z7b05wQgAIdkVE" youtubeLinki="https://www.youtube.com/embed/9Cp_fMO8bj8"/>
            <ArtistAlbumCardSmall sanatciAdi="Aydan Kanatlı" albumAdi="Dünden Bugüne" spofityLinki="https://open.spotify.com/embed/track/0PrxoeWhz1Uu8E4s1Yfk7k" youtubeLinki="https://www.youtube.com/embed/videoseries?si=jwLfeU0KA4RP2b0W&amp;list=OLAK5uy_knRkRUNWgkxoHsIe0dZqWZAtw12Fpru8Q"/>
            <ArtistAlbumCardSmall sanatciAdi="Monica Molina" albumAdi="Mi Fortuna" spofityLinki="https://open.spotify.com/embed/track/4FFJu8hj3ekfGmOZZKxgGU" youtubeLinki="https://www.youtube.com/embed/UA5lS-gGrek"/>
            <ArtistAlbumCardSmall sanatciAdi="Elvan Elvan" albumAdi="Boş Çerçeve" spofityLinki="https://open.spotify.com/embed/track/0EmV4aimCbSsRHc5yd3ldm" youtubeLinki="https://www.youtube.com/embed/wQNoSiYjq0E"/>
            <ArtistAlbumCardSmall sanatciAdi="Pınar Sezgi" albumAdi="Sen Unutsan Da" spofityLinki="https://open.spotify.com/embed/track/5AI1WjEtM85H9F5LI3iQGF" youtubeLinki="https://www.youtube.com/embed/2Cfp67HCflA"/>
            <ArtistAlbumCardSmall sanatciAdi="Sera Tübek" albumAdi="Gezegen" spofityLinki="https://open.spotify.com/embed/track/31XZYZe3pQkTvP2gaEntQT" youtubeLinki="https://www.youtube.com/embed/DENFaeRPKcU"/>
            <ArtistAlbumCardSmall sanatciAdi="Barış Yıldırım" albumAdi="Sen Gül Yeter" spofityLinki="https://open.spotify.com/embed/track/4MWzGIDJ6j67Bc52uCfDAD" youtubeLinki="https://www.youtube.com/embed/OK6jnPl55fk"/>
            <ArtistAlbumCardSmall sanatciAdi="Pınar Sezgi" albumAdi="Boşver" spofityLinki="https://open.spotify.com/embed/track/1Zi5FWeP6qDlziSfNVbrst" youtubeLinki="https://www.youtube.com/embed/YSDs5RX3kr0"/>
            <ArtistAlbumCardSmall sanatciAdi="Nilsu" albumAdi="İstanbul’un Havası" spofityLinki="https://open.spotify.com/embed/track/6FOJ3U3joNWkVNsjV21QjQ" youtubeLinki="https://www.youtube.com/embed/_0mQtLT_mH8"/>
            <ArtistAlbumCardSmall sanatciAdi="Yeşim Vatan" albumAdi="Dokunsana" spofityLinki="https://open.spotify.com/embed/track/6qSYPaZ5Vhl2rSUIpu3VC6" youtubeLinki="https://www.youtube.com/embed/hlISAerB53E"/>
            <ArtistAlbumCardSmall sanatciAdi="Elif Akbaş" albumAdi="Silinsin Anılar" spofityLinki="https://open.spotify.com/embed/track/3Y6ZLBDkYPRdLbILQFmD9T" youtubeLinki="https://www.youtube.com/embed/uI15sms2vEM"/>
            <ArtistAlbumCardSmall sanatciAdi="Bros" albumAdi="Zapt Edemiyorum" spofityLinki="https://open.spotify.com/embed/track/0All71lJLmPRhWzwKvo0kD" youtubeLinki="https://www.youtube.com/embed/XWGdPAoZi8M"/>
            <ArtistAlbumCardSmall sanatciAdi="Yılmaz Mengüş" albumAdi="Veda Yoktur Aşkta" spofityLinki="https://open.spotify.com/embed/track/0kygB41lXhTNg1jFqJ9Vwf" youtubeLinki="https://www.youtube.com/embed/IMqUfsKbzRE"/>
            <ArtistAlbumCardSmall sanatciAdi="Nilsu" albumAdi="Adam Olamadık" spofityLinki="https://open.spotify.com/embed/track/5fOmIC3x4nSOBKJfRdixTW" youtubeLinki="https://www.youtube.com/embed/dy3BVCvsVL0"/>
            <ArtistAlbumCardSmall sanatciAdi="Faruk Emre" albumAdi="Tut Çek" spofityLinki="https://open.spotify.com/embed/track/1cZ7dLWJmM74ztZ1JjmAU5" youtubeLinki="https://www.youtube.com/embed/eRzBzEizzWk"/>
            <ArtistAlbumCardSmall sanatciAdi="Yeşim Vatan" albumAdi="Hepsi Hikaye" spofityLinki="https://open.spotify.com/embed/track/7kLPeh798gsAf4d8DLjKWK" youtubeLinki="https://www.youtube.com/embed/A-Eo3NR5qLQ"/>
            <ArtistAlbumCardSmall sanatciAdi="Hasan Cebeci" albumAdi="Cool" spofityLinki="https://open.spotify.com/embed/track/2uxffgHg8LI38oFamFFsQp" youtubeLinki="https://www.youtube.com/embed/YrNZZYr2uzs"/>

          </Container>
      ):(
          <Container style={containerStyle} maxWidth="lg">
            <ArtistAlbumCard sanatciAdi="Monica Molina" albumAdi="De Mi Esperanza" spofityLinki="https://open.spotify.com/embed/track/79CbSlh6Z7b05wQgAIdkVE" youtubeLinki="https://www.youtube.com/embed/9Cp_fMO8bj8"/>
            <ArtistAlbumCard sanatciAdi="Aydan Kanatlı" albumAdi="Dünden Bugüne" spofityLinki="https://open.spotify.com/embed/track/0PrxoeWhz1Uu8E4s1Yfk7k" youtubeLinki="https://www.youtube.com/embed/videoseries?si=jwLfeU0KA4RP2b0W&amp;list=OLAK5uy_knRkRUNWgkxoHsIe0dZqWZAtw12Fpru8Q"/>  
            <ArtistAlbumCard sanatciAdi="Monica Molina" albumAdi="Mi Fortuna" spofityLinki="https://open.spotify.com/embed/track/4FFJu8hj3ekfGmOZZKxgGU" youtubeLinki="https://www.youtube.com/embed/UA5lS-gGrek"/>           
            <ArtistAlbumCard sanatciAdi="Elvan Elvan" albumAdi="Boş Çerçeve" spofityLinki="https://open.spotify.com/embed/track/0EmV4aimCbSsRHc5yd3ldm" youtubeLinki="https://www.youtube.com/embed/wQNoSiYjq0E"/>
            <ArtistAlbumCard sanatciAdi="Pınar Sezgi" albumAdi="Sen Unutsan Da" spofityLinki="https://open.spotify.com/embed/track/5AI1WjEtM85H9F5LI3iQGF" youtubeLinki="https://www.youtube.com/embed/2Cfp67HCflA"/>
            <ArtistAlbumCard sanatciAdi="Sera Tübek" albumAdi="Gezegen" spofityLinki="https://open.spotify.com/embed/track/31XZYZe3pQkTvP2gaEntQT" youtubeLinki="https://www.youtube.com/embed/DENFaeRPKcU"/>
            <ArtistAlbumCard sanatciAdi="Barış Yıldırım" albumAdi="Sen Gül Yeter" spofityLinki="https://open.spotify.com/embed/track/4MWzGIDJ6j67Bc52uCfDAD" youtubeLinki="https://www.youtube.com/embed/OK6jnPl55fk"/>
            <ArtistAlbumCard sanatciAdi="Pınar Sezgi" albumAdi="Boşver" spofityLinki="https://open.spotify.com/embed/track/1Zi5FWeP6qDlziSfNVbrst" youtubeLinki="https://www.youtube.com/embed/YSDs5RX3kr0"/>
            <ArtistAlbumCard sanatciAdi="Nilsu" albumAdi="İstanbul’un Havası" spofityLinki="https://open.spotify.com/embed/track/6FOJ3U3joNWkVNsjV21QjQ" youtubeLinki="https://www.youtube.com/embed/_0mQtLT_mH8"/>
            <ArtistAlbumCard sanatciAdi="Yeşim Vatan" albumAdi="Dokunsana" spofityLinki="https://open.spotify.com/embed/track/6qSYPaZ5Vhl2rSUIpu3VC6" youtubeLinki="https://www.youtube.com/embed/hlISAerB53E"/>
            <ArtistAlbumCard sanatciAdi="Elif Akbaş" albumAdi="Silinsin Anılar" spofityLinki="https://open.spotify.com/embed/track/3Y6ZLBDkYPRdLbILQFmD9T" youtubeLinki="https://www.youtube.com/embed/uI15sms2vEM"/>
            <ArtistAlbumCard sanatciAdi="Bros" albumAdi="Zapt Edemiyorum" spofityLinki="https://open.spotify.com/embed/track/0All71lJLmPRhWzwKvo0kD" youtubeLinki="https://www.youtube.com/embed/XWGdPAoZi8M"/>
            <ArtistAlbumCard sanatciAdi="Yılmaz Mengüş" albumAdi="Veda Yoktur Aşka" spofityLinki="https://open.spotify.com/embed/track/0kygB41lXhTNg1jFqJ9Vwf" youtubeLinki="https://www.youtube.com/embed/IMqUfsKbzRE"/>
            <ArtistAlbumCard sanatciAdi="Nilsu" albumAdi="Adam Olamadık" spofityLinki="https://open.spotify.com/embed/track/5fOmIC3x4nSOBKJfRdixTW" youtubeLinki="https://www.youtube.com/embed/dy3BVCvsVL0"/>
            <ArtistAlbumCard sanatciAdi="Faruk Emre" albumAdi="Tut Çek" spofityLinki="https://open.spotify.com/embed/track/1cZ7dLWJmM74ztZ1JjmAU5" youtubeLinki="https://www.youtube.com/embed/eRzBzEizzWk"/>
            <ArtistAlbumCard sanatciAdi="Yeşim Vatan" albumAdi="Hepsi Hikaye" spofityLinki="https://open.spotify.com/embed/track/7kLPeh798gsAf4d8DLjKWK" youtubeLinki="https://www.youtube.com/embed/A-Eo3NR5qLQ"/>
            <ArtistAlbumCard sanatciAdi="Hasan Cebeci" albumAdi="Cool" spofityLinki="https://open.spotify.com/embed/track/2uxffgHg8LI38oFamFFsQp" youtubeLinki="https://www.youtube.com/embed/YrNZZYr2uzs"/>

          </Container>
      )}
      
    </>
  )
}

export default Music