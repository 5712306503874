import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import YouTubeIcon from '@mui/icons-material/YouTube';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import { NavLink } from "react-router-dom";


const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#782166',
    '&:hover': {
      backgroundColor: '#782166'
    },
    width: 150,
    borderRadius: 12,
    height: 35,
    fontSize:13,
    fontFamily:'Poppins',
    fontWeight: 700,
  }));

const cardStyle = {
    height: '100%', 
    backgroundColor: '#522875',
    marginTop: 30,
    marginBottom: 20,
    width: '100%',
}
const textStyle = {
    color:'white',
    fontFamily:'Poppins',
}
var navlinkStyle = {
    textDecoration: "none",
    color: 'white'
  };
  const imageStyle = {
    backgroundColor: 'grey',
    width: '100%',
    height: '100%',
    position: 'relative'
  }

export default function TelevisionCardRight({title, text, subTitle, subText, link, image}) {
  return (
    <>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" style={cardStyle}>
            <Grid item sx={{height: '100%', width: '35%', m:1,}}>
                <Box sx={{height: 300, width: '100%'}}>
                    <img src={image}  style={imageStyle}/>
                </Box>
            </Grid>
            <Grid item sx={{height: 300, width:'60%', m:1}}>
                <Grid container direction="column" justifyContent="space-between" alignItems="flex-start" sx={{height:'100%'}}>
                    <Grid item>
                        <Typography variant="h4" style={textStyle} sx={{pb:"20px !important", pt:"10px"}}>{title}</Typography>
                        <Typography variant="body2" style={textStyle} sx={{pb:"30px !important"}}>{text}</Typography>
                        <Typography variant="h6" style={textStyle} sx={{pb:"5px !important"}}>{subTitle}</Typography>
                        <Typography variant="body2" style={textStyle}>{subText}</Typography>
                    </Grid>
                    <Grid item width="100%" >
                        <Grid container justifyContent="space-between" direction="row" alignItems="center">
                            <Grid item>
                                <NavLink style={navlinkStyle} to="/television_detail"> 
                                    <ColorButton>
                                        TÜM BÖLÜMLER                                   
                                    </ColorButton>
                                </NavLink>
                            </Grid>
                            <Grid item>
                                <IconButton href={link}> 
                                    <YouTubeIcon sx={{color: 'white', fontSize: 60,p:0,m:0}}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    </>
  )
}
