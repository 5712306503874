import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import UsCard from './UsCard'
import portrait_1 from './images/US/ipek.png'
import portrait_2 from './images/US/Tolga.png'
import portrait_3 from './images/US/Muge.png'
import portrait_4 from './images/US/Ömer.jpg'
import portrait_5 from './images/US/Efe.jpg'


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import {useTranslation} from "react-i18next";


const firstContainerStyle = {
  height: 400,
  width: '100%'
}
const buttonStyle = {
  border: 'none',
  backgroundColor: 'white',
}
const popperStyle = {


}


function Us() {

  const {t, i18n}= useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleClick1 = (event) => {
    setAnchorEl1(anchorEl1 ? null : event.currentTarget);
  };

  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? 'simple-popper' : undefined;

  const handleClick2 = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? 'simple-popper' : undefined;

  const handleClick3 = (event) => {
    setAnchorEl3(anchorEl3 ? null : event.currentTarget);
  };

  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? 'simple-popper' : undefined;

  const handleClick4 = (event) => {
    setAnchorEl4(anchorEl4 ? null : event.currentTarget);
  };

  const open4 = Boolean(anchorEl4);
  const id4 = open4 ? 'simple-popper' : undefined;


  return (
    <>
      <Container maxWidth="lg">
        <Grid container direction="column" sx={{paddingTop: 10}} spacing={3}>
          <Grid item>
            <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" >
              <Grid item >
                  <UsCard  img={portrait_1} text={"İPEK İYİER"} cv={t("aboutUs.ipek")}/>
              </Grid>
              <Grid item style={{paddingTop: 50}}>
                {/* <button aria-describedby={id1} type="button" onClick={handleClick1} style={buttonStyle}> */}
                  <UsCard  img={portrait_2} text={"TOLGA KILIÇ"} cv={t("aboutUs.tolga")}/>
                {/* </button>
                <Popper id={id1} open={open1} anchorEl={anchorEl1}>
                  <Box sx={{ border: 1, p: 5, bgcolor: '#702269', color:'white',width: 500, }}>

                  </Box>
                </Popper> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item >
            <Container style={{width:386}}>              
                  <UsCard  img={portrait_3} text={"MÜGE ŞEN"} cv={t("aboutUs.muge")}/>
            </Container>                
          </Grid>
          <Grid item >
            <Container style={{width:386}}>              
              <UsCard  img={portrait_5} text={"EFE FİDAN"} cv={t("aboutUs.efe")}/>
            </Container>                
          </Grid>
          {/* <Grid item>
            <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start">
              <Grid item >                
                  <UsCard  img={portrait_5} text={"EFE FİDAN"} cv={t("aboutUs.efe")}/>
              </Grid>
              <Grid item>
                <UsCard  img={portrait_4} text={"ÖMER GÜVEN"} cv={t("aboutUs.omer")}/>
              </Grid> 
            </Grid> 
          </Grid>*/}
        </Grid>
      </Container>
    </>
  )
}

export default Us