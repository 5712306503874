import React from 'react'
import GalleryCard from './GalleryCard'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import OnHoverScrollContainer from "./CustomScrollDiv";
import gallery_1 from './images/Galeri/Alessandro Safina Antalya Aspendos.JPG';
import gallery_2 from './images/Galeri/Ayhan Sicimoğlu Bursa.JPG';
import gallery_3 from './images/Galeri/Ayşegül Aldinç Büyük Kulüp.jpg';
import gallery_4 from './images/Galeri/BGKO Suzan Kardeş Foto.JPG';
import gallery_5 from './images/Galeri/Buika Ankara CSO.JPG';
import gallery_6 from './images/Galeri/Buika Antalya Aspendos.jpg';
import gallery_7 from './images/Galeri/Buika Uniq İstanbul.jpg';
import gallery_8 from './images/Galeri/Burak Kut Metropol .jpeg';
import gallery_9 from './images/Galeri/Burcu Güneş Metropol.JPG';
import gallery_10 from './images/Galeri/Cristina Ramos, J. Antoine Aspendos.jpg';
import gallery_11 from './images/Galeri/Dhafer Youssef AKM Foto.JPG';
import gallery_12 from './images/Galeri/Fatma Turgut Metropol.jpg';
import gallery_13 from './images/Galeri/Ferman Akgül Metropol.jpeg';
import gallery_14 from './images/Galeri/Gipsy Kings Aspendos.jpg';
import gallery_15 from './images/Galeri/Işın Karaca Metropol.JPG';
import gallery_16 from './images/Galeri/Kovacs Ankara CerModern.jpg';
import gallery_17 from './images/Galeri/Kovacs Antalya Aspendos.jpg';
import gallery_18 from './images/Galeri/Kovacs Uniq İstanbul.jpg';
import gallery_19 from './images/Galeri/MFÖ Harbiye.jpg';
import gallery_20 from './images/Galeri/Monica Molina Pera Palas.jpg';
import gallery_21 from './images/Galeri/Nil Karaibrahimgil Harbiye.jpg';
import gallery_22 from './images/Galeri/P. Domingo, Karahan Aspendos.JPG';
import gallery_23 from './images/Galeri/Serdar Ortaç Harbiye 1.JPG';
import gallery_24 from './images/Galeri/Yüksek Sadakat  Metropol.JPG';
import gallery_25 from './images/Galeri/Yüksek Sadakat CerModern.jpg';
import gallery_26 from './images/Galeri/Yüksek Sadakat Harbiye 2.JPG';
import gallery_27 from './images/Galeri/MFÖ Rahmi Koç Müzesi.jpg';
import gallery_28 from './images/Galeri/Buika Maximiles Black Turgutreis Arena.jpg';
import gallery_29 from './images/Galeri/Büyük Ev Ablukada Maximiles Black Turgutreis Arena.JPG'; 
import gallery_30 from './images/Galeri/Cimri Maximiles Black Turgutreis Arena. .JPG'; 
import gallery_31 from './images/Galeri/Deli Bayramı Maximiles Black Turgutreis Arena.jpg'; 
import gallery_32 from './images/Galeri/Evgeny Grinko Maximiles Black Turgutreis Arena.jpg'; 
import gallery_33 from './images/Galeri/Fuat Güner İBB Başakşehir Kültür Merkezi.jpg'; 
import gallery_34 from './images/Galeri/Goran Bregovic Maximiles Black Turgutreis Arena.jpg'; 
import gallery_35 from './images/Galeri/Karsu Maximiles Black Turgutreis Arena.jpg'; 
import gallery_36 from './images/Galeri/Mor ve Ötesi Maximiles Black Turgutreis Arena.JPG'; 
import gallery_37 from './images/Galeri/Sevgili Arsız Ölüm -Dirmit Maximiles Black Turgutreis Arena.JPG'; 
import gallery_38 from './images/Galeri/Yasmin Levy Maximiles Black Turgutreis Arena.jpg'; 
import gallery_39 from './images/Galeri/Zengin Mutfağı Maximiles Black Turgutreis Arena.jpg'; 






const ContainerStyle = {
    
    marginTop: 30,
    marginBottom: 20,
    width: '100%',
    padding: 0,
    height: 800,
    
}
const GridStyle = {
    margin:0,
    width: '100%',
    paddingRight: 15,
}

export default function GalleryList() {
  return (
    <div>
    <Container style={ContainerStyle} maxWidth="xxl">
        <OnHoverScrollContainer>
            <Grid container  direction="row" justifyContent="space-between" alignItems="flex-start" style={GridStyle} spacing={0}>
            {/* {[0, 1, 2,4,5,6,7,8,9,10,11,12,13,14,15].map((value) => (
                <Grid key={value} item sx={{pt:"10px !important", pr: "5px"}} >
                    <GalleryCard imageHere={gallery_1} imageText={""}/>                          
                </Grid>
            ))} */}
                    <GalleryCard imageHere={gallery_1} imageText={"Alessandro Safina - Limak Filarmoni Orkestrası / Antalya Aspendos Antik Tiyatro"}/>
                    <GalleryCard imageHere={gallery_2} imageText={"Ayhan Sicimoğlu / Bursa Atış Fest"}/>                          
                    <GalleryCard imageHere={gallery_3} imageText={"Ayşegül Aldinç / Büyük Kulüp"}/>
                    <GalleryCard imageHere={gallery_4} imageText={"BGKO feat Suzan Kardeş / İstanbul AKM"}/>
                    <GalleryCard imageHere={gallery_38} imageText={"Buika / Maximiles Black Turgutreis Arena"} />
                    <GalleryCard imageHere={gallery_5} imageText={"Buika / Ankara CSO"}/>                          
                    <GalleryCard imageHere={gallery_6} imageText={"Buika / Antalya Aspendos Antik Tiyatro"}/>                          
                    <GalleryCard imageHere={gallery_29} imageText={"Büyük Ev Ablukada / Maximiles Black Turgutreis Arena"} />
                    <GalleryCard imageHere={gallery_10} imageText={"Cristina Ramos & Jonathan Antoine - Limak Filarmoni Orkestrası / Antalya Aspendos Antik Tiyatrosu"}/>                          
                    <GalleryCard imageHere={gallery_31} imageText={"Deli Bayramı / Maximiles Black Turgutreis Arena"} />
                    <GalleryCard imageHere={gallery_11} imageText={"Dhafer Youssef / İstanbul AKM"}/>     
                    <GalleryCard imageHere={gallery_32} imageText={"Evgeny Grinko / Maximiles Black Turgutreis Arena"} />
                    <GalleryCard imageHere={gallery_9} imageText={"Fuat Güner feat Burcu Güneş / İstanbul Metropol Avm"}/>                          
                    <GalleryCard imageHere={gallery_8} imageText={"Fuat Güner feat Burak Kut / İstanbul Metropol Avm"}/>                          
                    <GalleryCard imageHere={gallery_12} imageText={"Fuat Güner feat Fatma Turgut / İstanbul Metropol Avm"}/>                          
                    <GalleryCard imageHere={gallery_13} imageText={"Fuat Güner feat Ferman Akgül / İstanbul Metropol Avm"}/>                          
                    <GalleryCard imageHere={gallery_15} imageText={"Fuat Güner feat Işın Karaca / İstanbul Metropol Avm"}/>  
                    <GalleryCard imageHere={gallery_33} imageText={"Fuat Güner / İBB Başakşehir Kültür Merkezi"} />
                    <GalleryCard imageHere={gallery_24} imageText={"Fuat Güner feat Yüksek Sadakat / İstanbul Metropol Avm"}/>
                    <GalleryCard imageHere={gallery_14} imageText={"Gipsy Kings / Antalya Aspendos Antik Tiyatro"}/>                          
                    <GalleryCard imageHere={gallery_34} imageText={"Goran Bregovic / Maximiles Black Turgutreis Arena"} />
                    <GalleryCard imageHere={gallery_16} imageText={"Kovacs / Ankara CerModern"}/>                          
                    <GalleryCard imageHere={gallery_17} imageText={"Kovacs / Antalya Aspendos Antik Tiyatro"}/>                          
                    <GalleryCard imageHere={gallery_18} imageText={"Kovacs / İstanbul Maximum UNIQ Açıkhava"}/>   
                    <GalleryCard imageHere={gallery_19} imageText={"MFÖ / İstanbul Harbiye Açıkhava Sahnesi"}/>                          
                    <GalleryCard imageHere={gallery_27} imageText={"MFÖ / İstanbul Rahmi Koç Müzesi"}/>  
                    <GalleryCard imageHere={gallery_20} imageText={"Monica Molina / İstanbul Pera Palace Hotel"}/>                          
                    <GalleryCard imageHere={gallery_36} imageText={"Mor ve Ötesi / Maximiles Black Turgutreis Arena"} />
                    <GalleryCard imageHere={gallery_21} imageText={"Nil Karaibrahimgil / İstanbul Harbiye Açıkhava Sahnesi"}/>                                 
                    <GalleryCard imageHere={gallery_22} imageText={"Placido Domingo feat Murat Karahan & Limak Filarmoni Orkestrası / Antalya Aspendos Antik Tiyatro"}/>                          
                    <GalleryCard imageHere={gallery_23} imageText={"Serdar Ortaç / İstanbul Harbiye Açıkhava Sahnesi"}/>
                    <GalleryCard imageHere={gallery_37} imageText={"Sevgili Arsız Ölüm - Dirmit / Maximiles Black Turgutreis Arena"} />                          
                    <GalleryCard imageHere={gallery_26} imageText={"Yüksek Sadakat / İstanbul Harbiye Açıkhava Sahnesi"}/>
                    <GalleryCard imageHere={gallery_25} imageText={"Yüksek Sadakat / Ankara CerModern"}/>                          
                    <GalleryCard imageHere={gallery_39} imageText={"Zengin Mutfağı / Maximiles Black Turgutreis Arena"} />

            </Grid>
        </OnHoverScrollContainer>       
    </Container>

</div>
  )
}
