import React from 'react'
import Grid from '@mui/material/Grid'
import {Typography, Box,Button} from '@mui/material'
import Container from '@mui/material/Container'
import { useMediaQuery, useTheme, } from "@mui/material";
import {useTranslation} from "react-i18next";

// import MapOne from "./MapOne";

const pageStyle = {
    width:'100%',
    marginTop: 70,
    marginBottom: 150,
    paddingLeft: '5%',
    paddingRight: '5%'
}
const adressCardStyle = {
    width:'50%',

}
const adressCardSmallStyle = {
    width:'100%',

}
const subTextStyle = {
    fontFamily:'Poppins',
    textAlign: "left",
    color: '#565656',
    fontWeight: 500,
}
const titleTextStyle = {
    fontFamily:'Poppins',
    textAlign: "left",
    color: 'black',
    fontWeight: 700,       
}
const mapTextStyle = {
    fontFamily:'Poppins',
    color: 'white',
    fontWeight: 600,
    paddingRight: 10,
    fontSize: 15,
    paddingTop: 10,
    paddingBottom: 10,
}
const mapBox = {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: 300,
    backgroundColor: "Grey",
}
const buttonStyle = {
    margin: 0,
    padding:0,
    width: '100%',
    height: '100%',
    justifyContent: "right"
}


function ContactPage() {
    
    const theme = useTheme();
    const isDownMD = useMediaQuery(theme.breakpoints.down("md"));
    const {t, i18n}= useTranslation();

  return (
    <>
    {isDownMD ? ( 
        <Grid container sx={{ background:'#F7F7F7'}}>
            <Container maxWidth="lg" sx={{p:"0px !important",}}>       
                <Grid container justifyContent="center" spacing={0} style={pageStyle}>
                    <Grid item style={adressCardSmallStyle} sx={{pb:5}}>
                        <Grid container spacing={2} direction="column" >
                            <Grid item>
                                <Box style={mapBox}>
                                <iframe title="map1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3009.173084183726!2d29.043957315855717!3d41.04334387929725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac829d6d7ccef%3A0x409d004252674bb2!2zQmV5bGVyYmV5aSwgQXJhYmFjxLFsYXIgU2suIE5vOjI4LCAzNDY3NiDDnHNrw7xkYXIvxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1676308540316!5m2!1str!2str" width="100%" height="100%" loading="lazy"></iframe>

                                </Box>
                                <Box sx={{height: '100%', backgroundColor: '#90278E'}}>
                                    <Button style={buttonStyle} href="https://www.google.com/maps/dir//Beylerbeyi,+Arabacılar+Sk.+No:28,+34676+Üsküdar%2Fİstanbul/@41.043344,29.046146,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x14cac829d6d7ccef:0x409d004252674bb2!2m2!1d29.046146!2d41.0433439!3e0?hl=tr">
                                    <Typography variant="h6"  style={mapTextStyle}>YOL TARİFİ</Typography>
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6"  style={titleTextStyle}>{t("contactPage.istanbulOffice")}</Typography>
                                <Typography variant="body2"  style={subTextStyle}>+90 216 422 48 01</Typography>
                                {/* <Typography variant="body2"  style={subTextStyle}>info@musicarium.live</Typography> */}
                                <Typography variant="body2"  style={subTextStyle}>Yalıboyu Cad. Arabacılar Sok. No: 28 <br/> 34684 Beylerbeyi Üsküdar - İstanbul <br/> Türkiye</Typography>
                            </Grid>                  
                        </Grid>
                    </Grid>
                    <Grid item style={adressCardSmallStyle}>
                        <Grid container spacing={2} direction="column" >
                            <Grid item>
                                <Box style={mapBox}>
                                <iframe title="map2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2370.3861018752923!2d9.998580816193275!3d53.550874780022376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b18ee20d555b07%3A0x5d6cbf33fc6ed029!2sM%C3%B6nckebergstra%C3%9Fe%2011%2C%2020095%20Hamburg%2C%20Almanya!5e0!3m2!1str!2str!4v1676309675487!5m2!1str!2str" width="100%" height="100%" loading="lazy"></iframe>
                                </Box>
                                <Box sx={{height: '100%', backgroundColor: '#90278E'}}>
                                    <Button style={buttonStyle} href="https://www.google.com/maps/dir//Mönckebergstraße+11,+20095+Hamburg,+Almanya/@53.5508748,9.9985808,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47b18ee20d555b07:0x5d6cbf33fc6ed029!2m2!1d10.0007695!2d53.5508748!3e0">
                                        <Typography variant="h6" style={mapTextStyle}>YOL TARİFİ</Typography>
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6"  style={titleTextStyle}>{t("contactPage.hamburgOffice")}</Typography>
                                <Typography variant="body2"  style={subTextStyle}>+49 01523 1323266</Typography>
                                <Typography variant="body2"  style={subTextStyle}>Ferdinandstrasse 29 <br/> 22043 Hamburg <br/> Almanya</Typography>
                            </Grid>                  
                        </Grid>
                    </Grid>
                </Grid>       
            </Container>
        </Grid>
    ):(
        <Grid container sx={{ background:'#F7F7F7'}}>
            <Container maxWidth="lg" sx={{p:"0px !important",}}>       
                <Grid container justifyContent="center" spacing={0} style={pageStyle}>
                    <Grid item style={adressCardStyle} sx={{pr: 5}}>
                        <Grid container spacing={2} direction="column" >
                            <Grid item>
                                <Box style={mapBox}>
                                <iframe title="map1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3009.173084183726!2d29.043957315855717!3d41.04334387929725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac829d6d7ccef%3A0x409d004252674bb2!2zQmV5bGVyYmV5aSwgQXJhYmFjxLFsYXIgU2suIE5vOjI4LCAzNDY3NiDDnHNrw7xkYXIvxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1676308540316!5m2!1str!2str" width="100%" height="100%" loading="lazy"></iframe>

                                </Box>
                                <Box sx={{height: '100%', backgroundColor: '#90278E'}}>
                                    <Button style={buttonStyle} href="https://www.google.com/maps/dir//Beylerbeyi,+Arabacılar+Sk.+No:28,+34676+Üsküdar%2Fİstanbul/@41.043344,29.046146,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x14cac829d6d7ccef:0x409d004252674bb2!2m2!1d29.046146!2d41.0433439!3e0?hl=tr">
                                    <Typography variant="h6"  style={mapTextStyle}>{t("contactPage.direction")}</Typography>
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6"  style={titleTextStyle}>{t("contactPage.istanbulOffice")}</Typography>
                                <Typography variant="body2"  style={subTextStyle}>+90 216 422 48 01</Typography>
                                {/* <Typography variant="body2"  style={subTextStyle}>info@musicarium.live</Typography> */}
                                <Typography variant="body2"  style={subTextStyle}>Yalıboyu Cad. Arabacılar Sok. No: 28 <br/> 34684 Beylerbeyi Üsküdar - İstanbul <br/> Türkiye</Typography>
                            </Grid>                  
                        </Grid>
                    </Grid>
                    <Grid item style={adressCardStyle} sx={{pl: 5}}>
                        <Grid container spacing={2} direction="column" >
                            <Grid item>
                                <Box style={mapBox}>
                                <iframe title="map2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2370.3861018752923!2d9.998580816193275!3d53.550874780022376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b18ee20d555b07%3A0x5d6cbf33fc6ed029!2sM%C3%B6nckebergstra%C3%9Fe%2011%2C%2020095%20Hamburg%2C%20Almanya!5e0!3m2!1str!2str!4v1676309675487!5m2!1str!2str" width="100%" height="100%" loading="lazy"></iframe>
                                </Box>
                                <Box sx={{height: '100%', backgroundColor: '#90278E'}}>
                                    <Button style={buttonStyle} href="https://www.google.com/maps/dir//Mönckebergstraße+11,+20095+Hamburg,+Almanya/@53.5508748,9.9985808,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47b18ee20d555b07:0x5d6cbf33fc6ed029!2m2!1d10.0007695!2d53.5508748!3e0">
                                        <Typography variant="h6" style={mapTextStyle}>{t("contactPage.direction")}</Typography>
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6"  style={titleTextStyle}>{t("contactPage.hamburgOffice")}</Typography>
                                {/* <Typography variant="body2"  style={subTextStyle}>info@musicarium.live</Typography> */}
                                <Typography variant="body2"  style={subTextStyle}>+49 01523 1323266</Typography>
                                <Typography variant="body2"  style={subTextStyle}>Ferdinandstrasse 29 <br/> 22043 Hamburg <br/> Almanya</Typography>
                            </Grid>                  
                        </Grid>
                    </Grid>
                </Grid>       
            </Container>
        </Grid>
    )}    
    

    </>
  )
}

export default ContactPage